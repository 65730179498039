import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import DownloadHero from '../components/Download/DownloadHero'

const Download = ({ data }) => {
  const { seoTitle, seoDescriptions, slug, label, heading } =
    data.contentfulDownload

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescriptions?.seoDescriptions}
        pathname={slug}
      />
      <DownloadHero label={label} heading={heading} />
    </>
  )
}

export default Download

export const pageQuery = graphql`
  query Download($slug: String!) {
    contentfulDownload(slug: { eq: $slug }) {
      seoTitle
      seoDescriptions {
        seoDescriptions
      }
      seoImage {
        gatsbyImageData
      }
      slug
      label
      heading
    }
  }
`
