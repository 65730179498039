import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import {
  DownloadHeroMain,
  DownloadHeroLinks,
  DownloadHeroLink,
} from './index.style'
import Container from '../../Container'
import Spacer from '../../Spacer'
import { Heading1, TextBodySmall } from '../../TextStyles'
import logoAppStore from '../../Nav/images/app-store.svg'
import logoGooglePlay from '../../Nav/images/google-play.svg'
import AnimateSlideIn from '../../animation/AnimateSlideIn'
import AnimateSplitText from '../../animation/AnimateSplitText'
import AnimateFadeIn from '../../animation/AnimateFadeIn'

const DownloadHero = ({ label, heading }) => {
  const { contentfulGlobals } = useStaticQuery(graphql`
    query {
      contentfulGlobals {
        appStoreDownloadUrl
        googlePlayDownloadUrl
      }
    }
  `)

  const { appStoreDownloadUrl, googlePlayDownloadUrl } = contentfulGlobals

  return (
    <DownloadHeroMain>
      <Container>
        <Spacer size={[160, 394]} />

        <TextBodySmall>
          <AnimateSlideIn>{label}</AnimateSlideIn>
        </TextBodySmall>

        <Spacer size={[16, 40]} />

        <Heading1 as="h1">
          <AnimateSplitText>{heading}</AnimateSplitText>
        </Heading1>

        <Spacer size={[24, 60]} />

        <DownloadHeroLinks>
          <DownloadHeroLink href={appStoreDownloadUrl}>
            <AnimateFadeIn>
              <img src={logoAppStore} alt="Available on the App Store" />
            </AnimateFadeIn>
          </DownloadHeroLink>

          <DownloadHeroLink href={googlePlayDownloadUrl}>
            <AnimateFadeIn>
              <img src={logoGooglePlay} alt="Get it on Google Play" />
            </AnimateFadeIn>
          </DownloadHeroLink>
        </DownloadHeroLinks>

        <Spacer size={[120, 394]} />
      </Container>
    </DownloadHeroMain>
  )
}

DownloadHero.propTypes = {
  label: PropTypes.string,
  heading: PropTypes.string,
}

export default DownloadHero
