import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'

export const DownloadHeroMain = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`

export const DownloadHeroLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const DownloadHeroLink = styled.a`
  display: block;
  ${clamp('width', 160, 197)}
  ${clamp('margin-top', 7, 20)}
  
  :first-child {
    ${clamp('margin-right', 7, 20)}
  }
`
